import { AppState, AppThunkDispatch } from '../../store';
import {
  forceRefreshGrid,
  getAvailableScopeMembers,
  getScope,
  newScope,
  importVersion,
  resetScope
} from '../../state/scope/scope.actions';
import './_Scopebar.scss';
import { ScopebarValueProps, ScopebarOwnProps } from './Scopebar.types';
import { DropdownItemProps } from 'semantic-ui-react';
import { Workflow, ScopeMemberInfo, SCOPECREATE_WITH_WP, TopMembers } from '../../services/Scope.client';
import type { ScopeCreateRequest } from 'services/Scope.client';
import {
  TIME,
  PerspectivePaths
} from '../../utils/domain/constants';
import {
  getScopeReadyData,
  getScopeId,
  getTopMembers
} from '../../state/scope/Scope.types';
import { PlanMetadata } from 'state/scope/codecs/PlanMetadata';
import { fetchWorkflows } from 'state/scope/scope.actions';
import { setRightContainerContent, toggleRightContainer } from 'components/RightContainer/RightContainer.slice';
import { isEmpty } from 'lodash';

export function mapStateToProps(state: AppState): ScopebarValueProps {
  const readyScope = getScopeReadyData(state.scope);
  const id = getScopeId(state.scope);
  const labelDimensions = state.settings.dimensionLabelProperty;

  // TODO: Revisit making ScopebarValueProps a single property contining an optional fusion
  if (!readyScope || !id) {
    // Instead, we will simply attempt to produce sane defaults as an intermediate
    return {
      selectedMembers: undefined,
      // ScopePending can return a scope id with no data while the rest of the scope loads
      // we need the id to remain stable in these cases to maintain various loading spinner states
      scopeId: id,
      availableMembers: state.viewConfigSlice.availableMembers,
      inSeason: state.viewConfigSlice.inSeason,
      scopeConfig: undefined,
      eopOptions: [],
      initialized: false,
      facet: state.viewConfigSlice.facet,
      settingsByKey: state.settings.entriesByKey,
      viewConfig: undefined,
      isFetchingScope: true,
      hasEditableRevision: false,
      scopeReady: false,
      scopeContexts: state.workingSets.contexts.filter((ws) => ws.initParams.type === SCOPECREATE_WITH_WP),
      commentsOpen: state.rightContainer.content.type === 'comments' && state.rightContainer.isOpen,
      anyPlansUnititialized: false,
      labelDimensions,
      pendingWrites: -1
    };
  }

  const { eopOptions } = readyScope;
  const hasEditableRevision = readyScope.hasEditableRevision;

  const availableMembers = state.viewConfigSlice.availableMembers;
  return {
    selectedMembers: getTopMembers(readyScope),
    scopeId: id,
    availableMembers: availableMembers,
    inSeason: state.viewConfigSlice.inSeason,
    viewConfig: state.viewConfigSlice.viewConfig,
    facet: state.viewConfigSlice.facet,
    scopeConfig: readyScope.mainConfig,
    eopOptions: eopOptions,
    timeMembers: availableMembers ? availableMembers.space[TIME].map(memberToDropdown) : [],
    initialized: readyScope.initialized,
    settingsByKey: state.settings.entriesByKey,
    isFetchingScope: readyScope.isFetching,
    hasEditableRevision: hasEditableRevision,
    // TODO: Eliminate this at some point
    scopeReady: true, // We are ready enough if we got have ScopeReadyData
    scopeContexts: state.workingSets.contexts.filter((ws) => ws.initParams.type === SCOPECREATE_WITH_WP),
    commentsOpen: state.rightContainer.content.type === 'comments' && state.rightContainer.isOpen,
    anyPlansUnititialized: !isEmpty(readyScope.mainConfig.uninitializedPlans!),
    labelDimensions,
    pendingWrites: readyScope.pendingWrites
  };
}

// Scope member's have id strings, seeds have id numbers
type memberSlice = Pick<ScopeMemberInfo, 'id' | 'name'> | Pick<PlanMetadata, 'id' | 'name'>;

export const memberToDropdown = (member: memberSlice): DropdownItemProps => {
  return { text: member.name, value: member.id };
};

export const sortByCreatedAt = (lMem: PlanMetadata, rMem: PlanMetadata) => {
  return rMem.createdAt.unix() - lMem.createdAt.unix();
};

function narrowWorkflow(workflow: string): Workflow {
  if (workflow === 'in-season' || workflow === 'pre-season') {
    return workflow;
  }
  return 'pre-season';
}

export const mapDispatchToProps = (
  dispatch: AppThunkDispatch,
  ownProps: ScopebarOwnProps
) => {
  const client = ownProps.serviceEnv.axios;
  return {
    onOpenScope: () => {
      // TODO: only refetch members when switching between TD/BU
      return dispatch(getAvailableScopeMembers(client));
    },
    onAcceptScope: (topMembers: TopMembers, workflow: string) => {
      const request: ScopeCreateRequest = {
        initParams: { type: 'with-wp' },
        workflow: narrowWorkflow(workflow),
        anchor: topMembers
      };
      return dispatch(() => {
        return dispatch<Promise<unknown>>(newScope(ownProps.serviceEnv.axios, request));
      });
    },
    updateScopeInfo: (scopeId: string, destinationPerspective?: PerspectivePaths) => {
      return dispatch(getScope(scopeId, destinationPerspective));
    },
    requestWorkflows: () => dispatch(fetchWorkflows()),
    onforceRefreshGrid: () => dispatch(forceRefreshGrid()),
    clearScope: () => dispatch(resetScope()),
    toggleRightContainer: () => {
      dispatch(setRightContainerContent({ type: 'comments' }));
      dispatch(toggleRightContainer());
    }
  };
};
