import Modal from '@trendmicro/react-modal';
import * as React from 'react';
import { useCallback } from 'react';
import {
  Dropdown,
  Grid,
  Header,
  Segment,
  Button,
  DropdownItemProps,
  StrictDropdownItemProps,
  DropdownProps
} from 'semantic-ui-react';
import './_InitializePlan.scss';
import { AppState, AppThunkDispatch } from 'store';
import { DEFAULT_DIMENSIONS, SCOPETYPE_ACTUALS, SCOPETYPE_PLAN, TIME } from 'utils/domain/constants';
import {
  ReseedPlanModalOwnProps,
  seedToDropdownItemProps,
  getDefaultOptionFromTime
} from 'components/Reseed/ReseedPlanModal';
import { connect } from 'react-redux';
import { SeedActuals, SeedPlan } from 'state/scope/ScopeManagement.slice';
import { getScopeReadyData } from 'state/scope/Scope.types';
import { uniqBy, groupBy, isEmpty, head, startCase, last, isNil, get } from 'lodash';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import {
  useHandleKeyPress
} from 'utils/component/hooks/hooks';
import { SeedInfoSelections } from 'components/Scopebar/Scopebar.types';
import classNames from 'classnames';
import {
  forceRefreshGrid,
  getScope,
  seedAvailableScope, setEop
} from '../../state/scope/scope.actions';
import {
  findEarliestPlan,
  getLastTimeMember,
  findEarliestPlans,
  getScopeObject
} from 'components/Scopebar/ScopeUtils';
import { toast } from 'react-toastify';
import { memberToDropdown } from 'components/Scopebar/Scopebar.container';
import { PlanId } from 'state/scope/codecs/PlanMetadata';
import LoadingMask from 'components/LoadingMask/LoadingMask';

const mapStateToProps = (state: AppState) => {
  const { scope } = state;
  const readyScope = getScopeReadyData(scope);

  if (!readyScope ||
    isNil(state.settings.dimensionLabelProperty) ||
    !state.viewConfigSlice.availableMembers) {
    return {
      seedOptions: undefined
    };
  }

  const availableMembers = state.viewConfigSlice.availableMembers;
  const timeMembers = availableMembers ? availableMembers.space[TIME].map(memberToDropdown) : [];
  const earliestPlan = findEarliestPlan([
    ...readyScope.mainConfig.initializedPlans,
    ...readyScope.mainConfig.uninitializedPlans
  ]);

  let beforeTime: string | undefined = undefined;
  if (timeMembers && !isEmpty(timeMembers) && earliestPlan) {
    beforeTime = getLastTimeMember(timeMembers, earliestPlan.space.time)[0].value as string;
  }

  const dimensionLabel = state.settings.dimensionLabelProperty;
  const showEOP = readyScope.mainConfig.uninitializedPlans.some(plan => plan.space.time === earliestPlan.space.time);
  const anchor = getScopeObject(readyScope.mainConfig.memberTrees);

  return {
    scopeId: readyScope.mainConfig.id,
    uninitializedPlans: readyScope.mainConfig.uninitializedPlans,
    seedOptions: readyScope.seedOptions,
    balanceTimeOptions: getLastTimeMember(timeMembers, earliestPlan.space.time),
    balanceTime: beforeTime,
    earliestPlanTimeId: earliestPlan.space.time,
    eopOptions: readyScope.eopOptions,
    dimensionLabel,
    availableMembers,
    showEOP,
    anchor
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    seedAvailableScope: (seed: SeedActuals | SeedPlan) => dispatch(seedAvailableScope(seed)),
    updateEop: (
      applyTo: number,
      eopId: number | string,
    ) => {
      return dispatch(setEop(applyTo, eopId))
        .then(() => dispatch(forceRefreshGrid()));
    },
    getScope: (scopeId: string) => dispatch(getScope(scopeId))
  };
};

export type InitializePlanPropsValueProps = ReturnType<typeof mapStateToProps>;
export type InitializePlanPropsDispatchProps = ReturnType<typeof mapDispatchToProps>;
type InitializePlanProps = ReseedPlanModalOwnProps &
InitializePlanPropsValueProps &
InitializePlanPropsDispatchProps & {
  balanceTime: string,
  balanceTimeOptions: StrictDropdownItemProps[],
  copyVersion: string,
  copyVersionOptions: DropdownItemProps[],
  onItemChange: (field: SeedInfoSelections, value?: number | string | undefined) => void
}

const InitializePlan = (props: InitializePlanProps) => {
  const {
    scopeId,
    loading,
    seedOptions,
    uninitializedPlans,
    onCancel,
    onOpen,
    balanceTimeOptions,
    balanceTime,
    earliestPlanTimeId,
    eopOptions,
    onSubmit,
    getScope: dispatchGetScope,
    seedAvailableScope: dispatchedSeedAvailableScope,
    updateEop,
    dimensionLabel,
    showEOP,
    availableMembers
  } = props;
  /*
  For the state to display modal, I'm thinking that we will setState = array.length of scope option,
  so whenever the state < array.length - 1, it will display first modal,
  then whenever it reach the last element in scope, it will display the last modal
  */
  const balancePlans = useMemo(() => {
    return uninitializedPlans ?
      findEarliestPlans(uninitializedPlans).filter((pln) => pln.space.time === earliestPlanTimeId) :
      [];
  }, [uninitializedPlans, earliestPlanTimeId]);
  const maxSteps = useMemo(() => uninitializedPlans ?
    (uninitializedPlans.concat(balancePlans).length - 1) :
    0,
  [uninitializedPlans, balancePlans]);
  const [initWizardStep, setInitWizardStep] = useState(0);

  const [
    currentBalancePlanId,
    setCurrentBalancePlanId
  ] = useState<PlanId | null>(head(balancePlans) ? head(balancePlans)!.id : null);
  useEffect(() => {
    if (!uninitializedPlans) { return; }
    const currentBalancePlan = get(findEarliestPlans(uninitializedPlans), initWizardStep - uninitializedPlans.length, null);
    setCurrentBalancePlanId(currentBalancePlan ? currentBalancePlan.id : currentBalancePlan);
  }, [initWizardStep, uninitializedPlans]);
  const [selectedBalanceTime, setSelectedBalanceTime] = useState<string | undefined>(balanceTime);
  const handleChangeBalanceTime = useCallback(
    (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      if (typeof data.value !== 'string' || typeof data.value !== 'number') {
        throw new Error('Balance time must be number or string');
      }
      setSelectedBalanceTime(data.value);
    }, [setSelectedBalanceTime]);
  const balanceVersionOptions = useMemo(() => {
    return !isEmpty(eopOptions) && currentBalancePlanId && eopOptions[Number(currentBalancePlanId)] ?
      eopOptions[Number(currentBalancePlanId)].map((opt) => {
        return opt.seedType === SCOPETYPE_ACTUALS ? {
          text: startCase(SCOPETYPE_ACTUALS),
          value: opt.seedTime
        } : {
          text: opt.name,
          value: opt.planId
        };
      }) : undefined;
  }, [currentBalancePlanId, eopOptions]);

  const [selectedBalanceVersion, setSelectedBalanceVersion] = useState<number | string | undefined>(head(balanceVersionOptions) ? head(balanceVersionOptions)?.value : undefined);
  useEffect(() => {
    if (!selectedBalanceVersion) {
      setSelectedBalanceVersion(head(balanceVersionOptions) ? head(balanceVersionOptions)?.value : undefined);
    }
  }, [balanceVersionOptions, eopOptions, selectedBalanceVersion]);

  const handleChangeBalanceVersion = useCallback(
    (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      const newVersion = data.value;
      if (newVersion && (typeof newVersion === 'number' || typeof newVersion === 'string')) {
        setSelectedBalanceVersion(newVersion);
      }
    }, [setSelectedBalanceVersion]);

  const maybeFirstUninitPlan = head(uninitializedPlans) ? head(uninitializedPlans) : undefined;
  const maybeFirstSeedOption = maybeFirstUninitPlan && seedOptions && !isEmpty(seedOptions) ?
    last(seedOptions[Number(currentBalancePlanId || maybeFirstUninitPlan.id)]) :
    undefined;
  const defaultSeedOption = useMemo(()=>{
    const maybePlan = get(uninitializedPlans, initWizardStep);
    return maybePlan ? last(seedOptions[maybePlan.id]) : undefined;
  },
  [initWizardStep, seedOptions, uninitializedPlans]);
  const [
    selectedSeedTime,
    setSelectedSeedTime
  ] = useState(maybeFirstSeedOption ? maybeFirstSeedOption.seedTime : undefined);
  const [
    selectedSeedVersion,
    setSelectedSeedVersion
  ] = useState(maybeFirstSeedOption ? maybeFirstSeedOption.seedType === SCOPETYPE_ACTUALS ? SCOPETYPE_ACTUALS : maybeFirstSeedOption.name : undefined);
  useEffect(() => {
    setSelectedSeedTime(defaultSeedOption ? defaultSeedOption.seedTime : undefined);
    setSelectedSeedVersion(maybeFirstSeedOption ? maybeFirstSeedOption.seedType === SCOPETYPE_ACTUALS ? SCOPETYPE_ACTUALS : maybeFirstSeedOption.name : undefined);
  }, [defaultSeedOption, maybeFirstSeedOption, seedOptions]);

  // fire the open callback on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onOpen(), []);
  const [mutationPending, setMutationPending] = useState(false);

  const handleNextButtonOnClick = useCallback(async () => {
    if (selectedSeedTime && selectedSeedVersion && seedOptions) {
      const maybeSeedPlanId = seedOptions[uninitializedPlans![initWizardStep].id].find((s) => s.seedTime === selectedSeedTime && s.name === selectedSeedVersion)!.planId;
      const newSeed: SeedActuals | SeedPlan = selectedSeedVersion === SCOPETYPE_ACTUALS && selectedSeedTime && !maybeSeedPlanId ?
        {
          seedTime: selectedSeedTime,
          seedType: SCOPETYPE_ACTUALS,
          name: SCOPETYPE_ACTUALS,
          planId: null,
          applyTo: uninitializedPlans![initWizardStep].id
        } : {
          seedTime: selectedSeedTime,
          seedType: SCOPETYPE_PLAN,
          name: selectedSeedVersion,
          planId: maybeSeedPlanId!,
          applyTo: uninitializedPlans![initWizardStep].id
        };
      setMutationPending(true);
      const maybeSeed = await dispatchedSeedAvailableScope(newSeed);
      if (maybeSeed.type === seedAvailableScope.rejected.type) {
        // failure, toast and don't go forward
        toast.error('An error occured seeding your scope');
      } else {
        const nextStepCount = initWizardStep + 1;
        if (!showEOP && nextStepCount > maxSteps) {
          dispatchGetScope(scopeId!).then(() => {
            onSubmit();
          });
        } else {
          setInitWizardStep(nextStepCount);
        }
      }
    }
    setMutationPending(false);
  }, [dispatchGetScope, dispatchedSeedAvailableScope, initWizardStep, maxSteps, onSubmit, scopeId, seedOptions, selectedSeedTime, selectedSeedVersion, showEOP, uninitializedPlans]);

  const handleSubmitBalance = useCallback(() => {
    if (!selectedBalanceVersion || !currentBalancePlanId) { return; }
    setMutationPending(true);

    updateEop(Number(currentBalancePlanId), selectedBalanceVersion).then(() => {
      setMutationPending(false);
      const nextStepCount = initWizardStep + 1;
      if (nextStepCount > maxSteps) {
        dispatchGetScope(scopeId!).then(() => {
          onSubmit();
        });
      } else {
        setInitWizardStep(nextStepCount);
      }
    }).catch(() => {
      toast.error('An error occured copying EOP to BOP');
      setMutationPending(false);
    });

    return () => {
      setMutationPending(false);
    };
  }, [currentBalancePlanId, dispatchGetScope, initWizardStep, maxSteps, onSubmit, scopeId, selectedBalanceVersion, updateEop]);
  const handleEnterPress = useHandleKeyPress(handleSubmitBalance);

  const handlePreviousButton = () => {
    if (initWizardStep > 1) {
      setInitWizardStep(initWizardStep - 1);
    } else {
      setInitWizardStep(0);
    }
  };

  return (
    <div>
      <div className="initialize-plan">
        <Grid
          columns={1}
          doubling={true}
          stretched={true}
        >
          { isEmpty(seedOptions) && !isEmpty(uninitializedPlans) ? (
            <div>
            Please wait while your plan is prepared
              <LoadingMask coverAll={true}/>
            </div>): null}
          <Grid.Column>
            {uninitializedPlans?.map((plan, planIdx) => {
              if (isEmpty(seedOptions) || !selectedSeedTime) { return <div key={planIdx} />; }
              const timeVersionMap = groupBy(seedOptions![Number(plan.id)], 'seedTime');
              const versionOptionDropdowns = selectedSeedTime && timeVersionMap[selectedSeedTime] ? timeVersionMap[selectedSeedTime].map(seedToDropdownItemProps) : [];
              const seedTimeDropdowns = uniqBy(seedOptions![Number(plan.id)], 'seedTime').map(t => {
                return { text: t.seedTime, value: t.seedTime };
              });
              const spaceLabels = Object.entries(plan.space)
                // @ts-ignore
                .filter(([dim, memberId]) => DEFAULT_DIMENSIONS.includes(dim))
                .map(([dim, memberId]) => {
                  return <ul>{availableMembers.space[dim]
                    .find((mem => mem.id === memberId))![dimensionLabel[dim]]}</ul>;
                });
              return (
                <Segment key={planIdx} className={initWizardStep !== planIdx ? 'init-hide' : ''}>
                  <Header as="h3" className="initialize-plan-header">
                    Seed the Plan for:
                    <div className="initialize-plan-header-text">
                      <ul>{spaceLabels}</ul>
                    </div>
                  </Header>
                  <div className="dropdown-group">
                    <div className="dropdown-group-label">
                      Select the Seed basis Plan Period
                    </div>
                    <Dropdown
                      fluid={false}
                      loading={loading}
                      disabled={!selectedSeedTime}
                      scrolling={true}
                      icon={<i className="chevron far fa-chevron-down icon" />}
                      options={seedTimeDropdowns}
                      value={selectedSeedTime}
                      onChange={(_e, data) => {
                        const newTime = data.value;
                        if (newTime && typeof newTime === 'string' && newTime !== selectedSeedTime) {
                          setSelectedSeedTime(getDefaultOptionFromTime(newTime, timeVersionMap).seedTime);
                        }
                      }}
                    />
                  </div>
                  <div className="dropdown-group">
                    <div className="dropdown-group-label">
                      Select the Seed Basis Plan Version
                    </div>
                    <Dropdown
                      fluid={true}
                      loading={loading}
                      disabled={!selectedSeedVersion}
                      icon={<i className="chevron far fa-chevron-down icon" />}
                      options={versionOptionDropdowns}
                      value={selectedSeedVersion}
                      onChange={(_e, data) => {
                        const newVersion = data.value;
                        if (newVersion === SCOPETYPE_ACTUALS || newVersion === 'plan') {
                          setSelectedSeedVersion(SCOPETYPE_ACTUALS);
                        } else {
                          setSelectedSeedVersion(data.value as string);
                        }
                      }}
                    />
                  </div>
                </Segment>);
            })}
            {!isEmpty(balancePlans) && balancePlans.map((ePlan, ePlanIdx) => {
              const spaceLabels = Object.entries(ePlan.space)
                // @ts-ignore
                .filter(([dim]) => DEFAULT_DIMENSIONS.includes(dim))
                .map(([dim, memberId]) => {
                  return <ul>{availableMembers.space[dim]
                    .find((mem => mem.id === memberId))![dimensionLabel[dim]]}</ul>;
                });
              return (<Segment className={initWizardStep === ePlanIdx + uninitializedPlans.length ? '' : 'init-hide'}>
                <Header as="h3" className="initialize-plan-header">
                  Copy EOP to BOP for:
                  <div className="initialize-plan-header-text">
                    <ul>{spaceLabels}</ul>
                  </div>
                </Header>
                <div className="dropdown-group">
                  <div className="dropdown-group-label">
                    Select the Plan period to Copy From
                  </div>
                  <Dropdown
                    fluid={true}
                    loading={loading}
                    icon={<i className="chevron far fa-chevron-down" />}
                    options={balanceTimeOptions}
                    value={selectedBalanceTime}
                    onChange={handleChangeBalanceTime}
                  />
                </div>
                <div className="dropdown-group">
                  <div className="dropdown-group-label">
                    Select the Plan Version
                  </div>
                  <Dropdown
                    fluid={true}
                    loading={loading}
                    icon={<i className="chevron far fa-chevron-down" />}
                    options={balanceVersionOptions}
                    value={selectedBalanceVersion}
                    onChange={handleChangeBalanceVersion}
                  />
                </div>
              </Segment>);
            })}
          </Grid.Column>
        </Grid>
        <Modal.Footer>
          <Button content="CANCEL" data-qa="initialize-btn-cancel" onClick={onCancel} />
          <Button
            content="PREVIOUS"
            disabled={initWizardStep === 0}
            data-qa="initialize-btn-previous"
            onClick={handlePreviousButton}
          />
          <Button
            content="NEXT"
            loading={mutationPending}
            data-qa="initialize-btn-next"
            className={classNames('initialize-plan-modal-button', initWizardStep <= maxSteps - balancePlans.length ? '' : 'init-hide')}
            onClick={handleNextButtonOnClick}
          />
          <Button
            content={initWizardStep === maxSteps ? 'COMPLETE' : 'NEXT'}
            className={classNames('initialize-plan-modal-button', initWizardStep > maxSteps - balancePlans.length ? '' : 'init-hide')}
            data-qa="initialize-btn-submit"
            onClick={handleSubmitBalance}
            loading={mutationPending}
            onKeyPress={handleEnterPress}
          />
        </Modal.Footer>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(InitializePlan);
